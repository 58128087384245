import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
const initialState = {
  notifications: [],
  unreadNotificationsCount: 0,
  NotificationStatus: "idle",
  NotificationError: null,
};

export const getNotification = createAsyncThunk(
  "notifications/getNotification",
  async () => {
    let data;
    try {
      const response = await axios.get(`/notifications`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notifications/deleteNotification",

  async (id, thunkAPI) => {
    let data;

    try {
      const response = await axios.patch(`/notifications/delete/${id}`);
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(getNotification());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const seenNotification = createAsyncThunk(
  "notifications/seenNotification",
  async (id, thunkAPI) => {
    let data;

    try {
      const response = await axios.patch(`/notifications/seen/${id}`);
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(getNotification());
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    handleSetUnreadNotifications: (state, action) => {
      state.unreadNotificationsCount = action.payload.count;
    },
    handleRemoveNotifications: (state, action) => {
      state.unreadNotificationsCount = action.payload;
    },
  },
  extraReducers: {
    [getNotification.pending]: (state) => {
      state.NotificationStatus = "loading";
    },

    [getNotification.fulfilled]: (state, action) => {
      state.NotificationStatus = "succeeded";
      state.notifications = action.payload;
    },

    [getNotification.rejected]: (state, action) => {
      state.NotificationStatus = "failed";
      state.NotificationError = action.error.message;
    },
  },
});
export const { handleSetUnreadNotifications, handleRemoveNotifications } =
  slice.actions;

export const reducer = slice.reducer;

export default slice;
